body{
  margin: 30px;
  background-color: rgb(167, 235, 236);
}
button{
  font-size : 20px; 
  width: fit-content; 
}
#heading{
  font-size: 35px;
  font-family: Geneva;
}
#warning{
  color: red;
}
#good{
  color: blue;
}
#survey{
  display: none;
}
#end{
  display: none;
}
.optionQuestion{
  font-size: 20px;
}
p{
  font-size: 20px;
  text-align: justify;
  text-justify: inter-word;
}
#startButton{
  display: flex;
  justify-content: center;
}